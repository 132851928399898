<template>
  <userTemplate :pattern="2">
    <div class="bg-danger font-28 pd-x-5">
      <el-row>
        <el-col
          :xs="{ span: 12, offset: 0 }"
          :sm="{ span: 8, offset: 4 }"
          :lg="{ span: 4, offset: 8 }"
        >
          <div>
            <p class="mg-y-6 text-white">{{ user.name }}</p>
          </div>
        </el-col>
        <el-col :xs="{ span: 12 }" :sm="{ span: 8 }" :lg="{ span: 4 }">
          <div>
            <p class="mg-y-6 text-white text-right">
              ข้อที่ {{ step.qId }}/{{ allExam.length }}
            </p>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-row class="pd-x-5 text-white text-center">
      <el-col
        :xs="{ span: 24, offset: 0 }"
        :sm="{ span: 16, offset: 4 }"
        :lg="{ span: 8, offset: 8 }"
        class="h-100 min-height-screen-255 is-flex ai-center"
      >
        <div class="w-100">
          <el-col :span="20" :offset="2">
            <el-button
              @click="answer(1)"
              class="w-100 mg-y-5 font-28 pd-y-4"
              type="primary"
              >ข้อที่ 1</el-button
            >
          </el-col>
          <el-col :span="20" :offset="2">
            <el-button
              @click="answer(2)"
              class="w-100 mg-y-5 font-28 pd-y-4"
              type="danger"
              >ข้อที่ 2</el-button
            >
          </el-col>
          <el-col :span="20" :offset="2">
            <el-button
              @click="answer(3)"
              class="w-100 mg-y-5 font-28 pd-y-4"
              type="warning"
              >ข้อที่ 3</el-button
            >
          </el-col>
        </div>
      </el-col>
    </el-row>
  </userTemplate>
</template>
<script>
import { HTTP } from "@/service/axios.js";
import userTemplate from "@/template/VoteUserTemplate";
export default {
  components: {
    userTemplate
  },
  data() {
    return {
      countTime: 5
    };
  },
  props: {
    routeParams: {
      type: Object,
      default: null
    }
  },
  computed: {
    exam() {
      let data = this.$store.state.exam.filter(a => a.no == this.step.qId);
      if (data.length > 0) {
        return data[0];
      } else {
        return null;
      }
    }
  },
  watch: {
    step() {
      if (this.step.state != "test") {
        this.checkRouterUser(this.step.state);
      }
    }
  },
  async mounted() {
    if (this.step.state != "test") {
      this.checkRouterUser(this.step.state);
    }
    this.intervalation = setInterval(() => {
      this.getState();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.intervalation);
  },
  methods: {
    answer(answer) {
      // console.log("ccc", answer);
      let obj = {
        playerId: this.user.id,
        roomId: this.room.roomId,
        qId: this.step.qId != "undefined" ? this.step.qId : this.step.qid,
        cId: answer,
        correct: this.exam.answer == answer ? true : false
      };
      HTTP.post(`answer`, obj).then(res => {
        // console.log(`answer`, res);
        if (res.data.success) {
          this.$router.push("/waiting-answer");
        } else if (res.data.data) {
          this.$router.push("/waiting-answer");
        }
      });
    }
  }
};
</script>